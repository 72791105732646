
import { userService } from "@/service";
import { useStore } from "@/store";
import { computed, defineComponent, reactive } from "vue";

export default defineComponent({
  name: "My",
  setup() {
    const store = useStore();
    const filterGender = (code: string): string => {
      const res = {
        "1": "男",
        "2": "女"
      }[code];
      return res || "未知";
    };
    const authenticationState = reactive({
      state: "",
      state_name: ""
    });
    const authentication = async () => {
      const { data } = await userService.authenticationDetail();
      authenticationState.state = data.data.state;
      authenticationState.state_name = data.data.state_name;
    };
    authentication();
    return {
      defaultAvatar: require("@/assets/icons/avatar@2x.png"),
      userInfo: computed(() => store.state.userInfo),
      filterGender,
      authenticationState
    };
  }
});
